<template>
  <div class="business-handling">
    <el-card class="box-card full-screen" v-for="(t,i) in businessList" :key="i">
      <el-row :gutter="20">
        <el-divider content-position="left">{{$t(t.name)}}</el-divider>
        <el-col :span="4" style="min-width:250px" v-for="(t2,i2) in t.children" :key="i2">
          <el-card shadow="hover" class="business-market" :body-style="{...onRenderCard(i2),cursor:isLoading||isDis?'no-drop':''}">
            <div v-if="isUsableRouter(t2.name)" @click.stop="onRouterLink({name:t2.urlLink,params:{code:t2.businessCode}})" class="inherent">
              <span>{{$t(t2.name)}}</span>
              <div class="page-loading" v-show="isLoading&&nowRouter==t2.name">
                <i class="el-icon-loading"></i>
              </div>
              <big class="tag1">{{t2.businessCode}}</big>
            </div>
            <el-tooltip v-else :content="$t('The feature is under development')" placement="top">
              <a class="inherent" style="cursor:not-allowed;">
                <span>{{$t(t2.name)}}</span>
                <big class="tag1">{{t2.businessCode}}</big>
              </a>
            </el-tooltip>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import business from '@/api/business';
import customerBusiness from '@/api/customerBusiness';
import storage from '@/assets/uilt/storage';
export default {
  name: "businessHandling",
  components: {},
  data () {
    return {
      businessList: [],
      usableRouter: [],

      /**记录当前路由，用于打开业务页面前显示加载中图标 */
      nowRouter: '',
    };
  },
  computed: {
    /**用于请求上班状态时的加载动画 */
    isLoading () { return this.$store.state.business.loading },
    isDis () { return this.$store.state.business.disable }
  },
  watch: {},
  methods: {
    /**机构获取可用业务 */
    getBusinessList () {
      let _this = this;
      business.getList({
        success: res => {
          if (res.code != 200) return;
          let parents = res.data.filter(function (t) { return t.parentId == 0 })

          parents.forEach(t => {
            /**剔除业务编号为14的业务，此业务是平台自有业务，不显示在业务列表 */
            t.children = res.data.filter(function (t2) { return t.id == t2.parentId && t2.businessCode != 14 })
          });
          _this.businessList = parents.sort(function (a, b) { return a.id - b.id });
        }
      })
    },
    customerGetBusinessList () {
      let _this = this;
      customerBusiness.getBusinessList({
        success: res => {
          if (res.code != 200) return;
          let parents = res.data.filter(function (t) { return t.parentId == 0 })
          parents.forEach(t => { t.children = res.data.filter(function (t2) { return t.id == t2.parentId }) });
          _this.businessList = parents.sort(function (a, b) { return a.id - b.id });
        }
      })
    },
    isUsableRouter (routerName) {
      let ur = this.usableRouter;
      if (ur.length < 1)
        this.usableRouter = ur = this.$router.options.routes.find(function (t) { return t.name == "layout" }).children

      return ur.find(function (t) { return t.name == routerName });
    },

    // 以下两个方法是为了实现上下班逻辑（onRouterLink，onRenderCard）
    /**打开业务页面，下班时业务模块为灰色并且点击不会进入页面，当isLoading==true时点击其它业务不生效 */
    onRouterLink (route) {
      if (this.isLoading)
        return;
      this.nowRouter = route.name;
      if (!this.isDis)
        this.$router.push(route)
    },
    /**设置card颜色，下班时业务模块为灰色，寓意禁止点击 */
    onRenderCard (index) {
      let style = { background: '#dbdbdb' };
      if (!this.isDis)
        style = { background: ['#409EFF', '#67C23A', '#E6A23C', '#F56C6C', '#909399'][index % 5] };
      return { padding: 0, color: '#fff', ...style };
    }
  },
  mounted () {
    let loginName = storage.local.get('loginName');
    if (!loginName)
      this.$router.push({ name: 'merchantLogin' })
    else if (loginName.name == "merchantLogin")
      this.getBusinessList();
    else if (loginName.name == "customerLogin")
      this.customerGetBusinessList()
  },
}
</script>
<style lang="less" >
.business-handling .business-item {
  .el-page-header {
    line-height: 40px;
    margin-left: 15px;
  }
}
</style>
<style  lang='less' scoped>
.business-handling {
  padding-bottom: 1px;
  .business-item {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #fff;
    z-index: 1;
  }
  .box-card {
    margin-bottom: 20px;
  }
  .business-market {
    margin-bottom: 20px;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    font-weight: 600;

    .inherent {
      position: relative;
      display: block;
      height: 100px;
      color: @white;
      span {
        position: absolute;
        top: 35px;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 1;
      }
      .page-loading {
        position: absolute;
        top: 5px;
        left: 5px;
      }
      .tag1 {
        position: absolute;
        bottom: 5px;
        right: 8px;
        font-size: 73px;
        font-weight: 100;
        font-family: fantasy;
        color: @french_grey;
        opacity: 0.3;
      }
      .iconfont {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 90px;
        color: #f9f9f9;
        z-index: 0;
      }
    }
  }
  .business-market:hover {
    transform: scale(1.05, 1.05);
  }

  .el-divider__text {
    // background: #f5f5f5;
    font-size: 16px;
  }
}
</style>